import axios from 'axios';
import { tracking } from '../di/tracking-di';
import { TrackingEvent } from '../common/tracking/tracking-event-models';


export type HasAccessResponse = {
    hasAccess: boolean;
    stateToken: string | null;
}

export async function checkMondayAccess(): Promise<HasAccessResponse> {
	const url = `${process.env.REACT_APP_BASE_URL}/monday/api-key`;
    try {
        const response = await axios.get(url);
        return response.data as HasAccessResponse;
    } catch (error: any) {
		tracking.sendEvent(
			0,
			0,
			TrackingEvent.MONDAY_API_KEY_FAILED,
			{
				error: JSON.stringify(error)
			}
		)	
        return {
            'hasAccess': false,
            'stateToken': null
        }
    }
}

export enum SubscriptionStatusType {
	MAX_USERS_REACHED = "max_users_reached",
	NO_ACTIVE_SUBSCRIPTION = "no_active_subscription",
	ACTIVE_SUBSCRIPTION = "active_subscription",
	NOT_INSTALLED = "not_installed",
	TRIAL_ENDED = "trial_ended",
	UNKNOWN = "unknown",
	NOT_INITIALIZED = "not_initialized"
}

export interface SubscriptionStatus {
    status: SubscriptionStatusType;
    message: string;    
}

export async function getSubscriptionStatus(): Promise<SubscriptionStatus> {
	const url = `${process.env.REACT_APP_BASE_URL}/monday/subscription/status`;
	try {
		const response = await axios.get(url);
		let subscriptionType = SubscriptionStatusType.UNKNOWN;
		switch (response.data.status) {
			case SubscriptionStatusType.ACTIVE_SUBSCRIPTION: subscriptionType = SubscriptionStatusType.ACTIVE_SUBSCRIPTION; break;
			case SubscriptionStatusType.MAX_USERS_REACHED: subscriptionType = SubscriptionStatusType.MAX_USERS_REACHED; break;
			case SubscriptionStatusType.NO_ACTIVE_SUBSCRIPTION: subscriptionType = SubscriptionStatusType.NO_ACTIVE_SUBSCRIPTION; break;
			case SubscriptionStatusType.NOT_INSTALLED: subscriptionType = SubscriptionStatusType.NOT_INSTALLED; break;
			case SubscriptionStatusType.TRIAL_ENDED: subscriptionType = SubscriptionStatusType.TRIAL_ENDED; break;
			default: subscriptionType = SubscriptionStatusType.UNKNOWN; break;
		}
		return {
			'status': subscriptionType,
			'message': response.data.message
		} as SubscriptionStatus
	} catch (error: any) {
		tracking.sendEvent(
			0,
			0,
			TrackingEvent.SUBSCRIPTION_API_FAILED,
			{
				error: JSON.stringify(error)
			}
		)

		return {
			'status': SubscriptionStatusType.UNKNOWN,
			'message': error.message
		}
	}
}