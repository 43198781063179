import { TrackingEvent } from "../common/tracking/tracking-event-models";
import TrackingService from "../services.ts/tracking-service";

class TrackingRepository {
	service = new TrackingService();

	// The backend first tries to retrieve the user_id and account_id from the sessionToken
	// If the sessionToken is not available, then user_id and account_id provided in the body will be used
	async sendEvent(
		userId: number,
		accountId: number,
		event: TrackingEvent,
		eventData: Record<string, string>
	): Promise<void> {
		await this.service.sendEvent({
			userId: userId,
			accountId: accountId,
			event: event,
			eventData: JSON.stringify(eventData)
		});
	}
}	

export default TrackingRepository;