import { useAuth } from "../auth/auth-context";
import { useUserInfo } from "../user/user-info-context";
import { useEffect, useCallback } from "react";
import { Button } from 'monday-ui-react-core';
import { tracking } from "../di/tracking-di";
import { TrackingEvent } from "../common/tracking/tracking-event-models";

function getMondayRedirectUri(accountSlug: string, stateToken: string): string {
    const clientId = process.env.REACT_APP_MONDAY_CLIENT_ID;
    const redirectCallbackUri = process.env.REACT_APP_MONDAY_REDIRECT_CALLBACK_URI;
    return `https://${accountSlug}.monday.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectCallbackUri}&state=${stateToken}`
}

const OAUTH_WINDOW_FEATURES = "width=1000,height=800,resizable=yes,scrollbars=yes,status=yes";

function AuthorizationScreen() {
    const { hasMondayAccess, mondayStateToken, refreshMondayAccess } = useAuth();
    const { userInfo } = useUserInfo();

    useEffect(() => {  
        const trackEvent = async () => {
            await tracking.sendEvent(
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.AUTHORIZATION_SCREEN_OPENED,
                {}
            );
        }

        trackEvent();
    }, [userInfo]);

    const handleMondayLogin = useCallback(async () => {
        if (!mondayStateToken || !userInfo.account.slug) {
            await tracking.sendEvent(
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.HANDLE_MONDAY_LOGIN_ERROR,
                {
                    error: `mondayStateToken = ${mondayStateToken}, accountSlug = ${userInfo.account.slug}. Please refresh the page and try again. Both should be non-null`
                }
            );
            alert("Something went wrong! Please refresh the page and try again.")
            return;
        }

        const mondayOauthUrl = getMondayRedirectUri(userInfo.account.slug as string, mondayStateToken as string);
        const authWindow: Window | null = window.open(mondayOauthUrl, '_blank', OAUTH_WINDOW_FEATURES);

        // Polling to check if the popup was closed, but only if authWindow is not null
        if (authWindow) {
            const pollTimer = setInterval(() => {
                if (authWindow.closed) {
                    clearInterval(pollTimer);
                    refreshMondayAccess();
                }
            }, 1000);
        } else {
            alert("Failed to redirect to monday.com. Please give browser access to open a popup window.");
            await tracking.sendEvent(
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.HANDLE_MONDAY_LOGIN_ERROR,
                {
                    error: "Failed to redirect to monday.com. Please give browser access to open a popup window."
                }
            )
        }
    }, [mondayStateToken, userInfo, refreshMondayAccess]);

    return (
        <div className="authorization-container">
            <div className="authorization-button-container">
                <Button
                    onClick={handleMondayLogin}
                        style={{ marginLeft: '10px' }}
                        disabled={hasMondayAccess}
                    >
                    Connect with monday
                </Button>
            </div>
        </div>
    );
}

export default AuthorizationScreen;