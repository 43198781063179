import { createContext, useContext, useState, useEffect } from "react";
import mSdk, { MondayClientSdk } from 'monday-sdk-js';
import { LoadingIndicator } from "../common/ui/loading-indicator";

interface MondaySdkContextType {
    mondaySdk: MondayClientSdk;
}
  
const MondaySdkContext = createContext<MondaySdkContextType | undefined>(undefined);

export const useMondaySdk = (): MondaySdkContextType => {
    const context = useContext(MondaySdkContext);
    if (!context) throw new Error('useMondaySdk must be used within a MondaySdkProvider');
    return context;
};

interface MondaySdkProviderProps {
    children: React.ReactNode;
}

export const MondaySdkProvider = ({ children }: MondaySdkProviderProps): JSX.Element => {
    const [mondaySdk, setMondaySdk] = useState<MondayClientSdk>();

    useEffect(() => {
        const sdk = mSdk()
        setMondaySdk(sdk);
    }, []);

    if (!mondaySdk) {
        return <LoadingIndicator />;
    }

    return (
        <MondaySdkContext.Provider value={{ mondaySdk }}>
            {children}
        </MondaySdkContext.Provider>
    );
};