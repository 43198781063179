import { Text } from "monday-ui-react-core";
import installApp from "../assets/install_app.png";
import verifyInstalledImage from "../assets/verify_app_installed.png";
import openAppsMenuFromItemMenu from "../assets/open_apps_menu_from_item_menu.png";
import connectWithMonday from "../assets/connect_with_monday.png";
import authorizeOauthScreen from "../assets/authorize_oauth_tokens.png";
import mondayApiKeysSaved from "../assets/monday_api_keys_saved.png";
import setAReminder from "../assets/set_a_reminder.png";
import reminderSuccess from "../assets/reminder_success.png";
import notificationDelivered from "../assets/notification_delivered.png";
import viewerGuestNotAuthorized from "../assets/viewer_guest_not_authorized.png";
import { Spacer } from "../common/ui/spacer";

function HowToUseScreen() {
    return (
        <div className="how-to-screen-container">
            <h1 style={{ width: 1200 }}>How to use the "Item Reminder" App for monday</h1>
            <StepComponent 
                message = "Step 0: Install the app from the monday marketplace" 
                description = {null}
                image = {installApp} />
            <StepComponent 
                message = "Step 1: Verify monday installation" 
                description = "Visit the admin section of you monday account and verify that the app is installed. If the installation was successful, you'll see the app in the list on this screen."
                image = {verifyInstalledImage} />
            <StepComponent 
                message = "Step 2: Open Apps Menu" 
                description = {`Click on the 3 dot menu next to the item you want to the set the reminder on. In the apps sections of the menu, find "Set a Reminder" from the list.`}
                image = {openAppsMenuFromItemMenu} />
            <StepComponent 
                message = {`Step 3: Click on "Connect with monday"`}
                description = {`Click on the "Connect with monday" button to connect the app with your monday account. This will allow the app to send you reminders' notification directly in monday.`}
                image = {connectWithMonday} />
            <StepComponent 
                message = {`Step 4: Authorize the app`}
                description = {`Click on the "Authorize" button to grant credentials to the app to send you reminders.`}
                image = {authorizeOauthScreen} />
            <StepComponent 
                message = {`Step 5: Authorization success`}
                description = {`When the authorization is successful, you'll see a success screen like the one below. No action is required on this screen. However, if you don't see the success message, you'll need to refresh the monday page and retry from step 3.`}
                image = {mondayApiKeysSaved} />
            <StepComponent 
                message = {`Step 6: Set a reminder`}
                description = {`After the app is authorized, you can set a reminder for the item. Click on the "Set a Reminder" button to schedule a reminder. Here you can set a date and time, optionally add any notes, and finally schedule the reminder.`}
                image = {setAReminder} />
            <StepComponent 
                message = {`Step 7: Reminder scheduled`}
                description = {`When a reminder is successfully scheduled, you'll see a success message like the one below.`}
                image = {reminderSuccess} />
            <StepComponent 
                message = {`Step 8: Notification delivered`}
                description = {`When the reminder is due, you'll receive a notification in monday. Any notes added will show up in the notification body as shown below. Click on the notification to view the item.`}
                image = {notificationDelivered} />
                <StepComponent 
                message = {`Appendix 1: Viewer or Guest cannot use the app`}
                description = {`A viewer or guest cannot use the app. If you see the screen below, you'll need to login with a valid monday user account to use the app. You can verify the user type in the top left corner of the page.`}
                image = {viewerGuestNotAuthorized} />
        </div>
    )
}

interface StepComponentProps {
    message: string;
    description: string | null;
    image: string | null;
}

function StepComponent({ message, description, image }: StepComponentProps) {
    return (
        <div className="step-container">
            <Text><h2>{message}</h2></Text>
            {description && <Text><i><p style={{ whiteSpace: "normal", width: 1200 }}>{description}</p></i></Text>}
            {image && <img style={{ height: 700, width: 1200, border: "1px solid #000000", borderRadius: 6 }} src={image} alt="Step" />}
            <Spacer size={4} />
        </div>
    )
}

export default HowToUseScreen;