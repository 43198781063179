import { AlertBanner, AlertBannerText } from "monday-ui-react-core"

export interface ShowToastProps {
    message: string;
    onClose: () => void;
}

export interface ShowErrorToastProps {
    message: string;
    onClose: () => void;
}

export const ShowToast = ({ message, onClose }: ShowToastProps) => {
    return <AlertBanner backgroundColor={AlertBanner.backgroundColors.POSITIVE} isCloseHidden={false} onClose={() => onClose()}>
        <AlertBannerText text={message} />
    </AlertBanner>
};

export const ShowErrorToast = ({ message, onClose }: ShowErrorToastProps) => {
    return <AlertBanner backgroundColor={AlertBanner.backgroundColors.NEGATIVE} isCloseHidden={false} onClose={() => onClose()}>
        <AlertBannerText text={message} />
    </AlertBanner>
}