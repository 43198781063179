import axios from "axios";
import { tracking } from "../di/tracking-di";
import { TrackingEvent } from "../common/tracking/tracking-event-models";

export interface RemindersBodyArgs {
	datetime: string;
	timezone: string;
	notes: string | null;
	accountId: number;
	userId: number;
	boardId: number;
	itemId: number;
}

export interface ReminderResponse {
	success: boolean;
	message: string;
}

class RemindersService {
	async setReminders(body: RemindersBodyArgs): Promise<ReminderResponse> {
		try {
			const response = await axios.post("https://api-snooze.cleverextensions.com/monday/reminder", body);
			return {
				success: response.status === 200,
				message: response.data.message
			};
		} catch (error: any) {
			tracking.sendEvent(
				body.userId,
				body.accountId,
				TrackingEvent.REMINDER_API_FAILED,
				{
					error: JSON.stringify(error)
				}
			);
			return {
				success: false,
				message: error.body.message
			};
		}
	}
}

export default RemindersService;