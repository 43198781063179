import { MondayItem } from "../common/models/monday-item-model";
import RemindersService, { ReminderResponse } from "../services.ts/reminders-service";

class RemindersRepository {
	private service = new RemindersService();

	async setReminders(
		dateTime: string, // Formatted as "2024-06-29T17:55"
		timezone: string,
		notes: string,
		accountId: number,
		userId: number,
		items: MondayItem[]
	): Promise<ReminderResponse | null> {
		if (items.length === 1) {
			const item = items[0];
			const result = await this.service.setReminders(
				{
					datetime: dateTime,
					timezone: timezone,
					notes: notes,
					accountId: accountId,
					userId: userId,
					boardId: item.boardId,
					itemId: item.itemId
				}
			)
			return result;
		}
		return null;
	} 
}

export default RemindersRepository;