import { useContext, createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { useMondaySdk } from '../monday-sdk/monday-sdk-context';
import { UserInfo } from '../common/models/user-info-model';
import { UserInfoResponse } from './user-info-response';
import { LoadingIndicator } from '../common/ui/loading-indicator';
import { tracking } from '../di/tracking-di';
import { TrackingEvent } from '../common/tracking/tracking-event-models';
import { GuestViewerUnauthorized } from '../common/ui/guest-viewer-unauthorized';

interface UserContextType {
  userInfo: UserInfo;
}

const UserInfoContext = createContext<UserContextType | undefined>(undefined);

export const useUserInfo = (): UserContextType => {
  const context = useContext(UserInfoContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

interface UserInfoProviderProps {
  children: ReactNode;
}

export const UserInfoProvider = ({ children }: UserInfoProviderProps): JSX.Element => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isUserInfoSuccess, setIsUserInfoSuccess] = useState<boolean | null>(null);
  const { mondaySdk } = useMondaySdk();

  const getUserInfo = useCallback(async () => {
    if (!mondaySdk) {
      return;
    }

    try {
      const query = `query {
        me {
          id
          utc_hours_diff
          time_zone_identifier
          account {
            id
            plan {
              max_users
              period
              tier
              version
            }
            slug
          }
        }
      }`

      const response: UserInfoResponse = await mondaySdk.api(query);
      setUserInfo(response.data.me);
      setIsUserInfoSuccess(true);
    } catch (error) {
      setIsUserInfoSuccess(false);
      await tracking.sendEvent(0, 0, TrackingEvent.USER_INFO_ERROR, { error: JSON.stringify(error) });
    }
  }, [mondaySdk]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    const trackEvent = async () => { 
      await tracking.sendEvent(
        0,
        0,
        TrackingEvent.USER_INFO_NOT_INITIALIZED,
        {}
      );
    }

    if (isUserInfoSuccess === undefined) {
      return;
    }
    
    if (!isUserInfoSuccess) {
      trackEvent();
    }
  }, [isUserInfoSuccess]);

  if (isUserInfoSuccess === null) {
    return <LoadingIndicator />;
  } 

  if (!isUserInfoSuccess) {
    return <GuestViewerUnauthorized />;
  }

  if (userInfo === null) {
    return <LoadingIndicator />;
  }

  return (
    <UserInfoContext.Provider value={{ userInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};