import { useMemo } from "react";
import { Heading } from "monday-ui-react-core";

interface PricingModel {
    seats: string;
    price: number;
    features: string[];
}

interface PricingCardProps {
    pricing: PricingModel;
}

function PricingScreen() {
    const pricingModels: PricingModel[] = useMemo(() => {
        return [
            {
                seats: "5",
                price: 5,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            },
            {
                seats: "10",
                price: 10,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            },
            {
                seats: "50",
                price: 25,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            },
            {
                seats: "100",
                price: 50,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            },
            {
                seats: "500",
                price: 75,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            },
            {
                seats: "1000",
                price: 100,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            },
            {
                seats: "5000",
                price: 250,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            },
            {
                seats: "Unlimited",
                price: 500,
                features: [
                    "Schedule reminders for any item",
                    "Ability to set multiple reminders per item",
                    "Add contextual notes to each reminder",
                    "Receive notifications via email or in-app notifications",
                ]
            }
        ]
    }, []);

    return (
        <div className="pricing-body">
            <div className="pricing-title">
                <Heading type={Heading.types.h1} size={Heading.sizes.LARGE} value="Remind Me! Pricing Page" />
            </div>
            <div className="pricing-container">
                {pricingModels.map((pricing, index) => (
                    <PricingCard key={index} pricing={pricing} />
                ))}
            </div>
        </div>
    )
}

function PricingCard({ pricing }: PricingCardProps) {
    return (
        <div className="pricing-card">
            <div className="card-header">Up to {pricing.seats} Seats</div>
            <div className="card-price">${pricing.price} <span>/ month</span></div>
            <div className="card-description">Full use of this app for up to {pricing.seats} team members.</div>
            <ul className="card-features">
                {pricing.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        </div>
    );
}

export default PricingScreen;