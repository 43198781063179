import axios from "axios";

export interface TrackingEventBodyArgs {
	userId: number;
	accountId: number;
	event: string;
	eventData: string;
}

class TrackingService {
	async sendEvent(
		body: TrackingEventBodyArgs
	): Promise<void> {
		try {
			await axios.post("https://api-snooze.cleverextensions.com/tracking/event", body);
		} catch (error: any) {
			// Do Nothing - Silently failing
		}
	}
}

export default TrackingService;