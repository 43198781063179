import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { MondayContext, ThemeConfig } from "../common/models/monday-context-model";
import { useMondaySdk } from "../monday-sdk/monday-sdk-context";
import { MondayItem } from "../common/models/monday-item-model";
import { LoadingIndicator } from "../common/ui/loading-indicator";
import { ThemeProvider } from "monday-ui-react-core";
import { GuestViewerUnauthorized } from "../common/ui/guest-viewer-unauthorized";

interface MondayContextType {
    context: MondayContext
    items: MondayItem[]
}

const MondayContextContext = createContext<MondayContextType | undefined>(undefined);

export const useMondayContext = (): MondayContextType => {
    const context = useContext(MondayContextContext);
    if (!context) throw new Error('useMondayContext must be used within a MondayContextProvider');
    return context;
}

interface MondayContextProviderProps {
    children: React.ReactNode;
}

export const MondayContextProvider = ({ children }: MondayContextProviderProps): JSX.Element => {
    const [context, setContext] = useState<MondayContext>(); 
    const [isContextSet, setIsContextSet] = useState<boolean>();
    const [items, setItems] = useState<MondayItem[]>([]); 
    const { mondaySdk } = useMondaySdk();

    const updateItems = useCallback(async () => {
        const currentItems = []
        if (context?.selectedPulsesIds) {
            for (let i = 0; context.selectedPulsesIds.length; i++) {
                const pulseId = context.selectedPulsesIds[i];
                currentItems.push(
                    {
                        itemId: pulseId,
                        boardId: context.boardId,
                        groupId: context.groupId
                    }
                )
            }
        } else if (context) {
            currentItems.push(
                {
                    itemId: context.itemId,
                    boardId: context.boardId,
                    groupId: context.groupId
                }
            )
        }
        setItems(currentItems);
    }, [context]);

    useEffect(() => {
        mondaySdk.listen("context", async (res: any) => {
            const c = res.data as MondayContext;
            setContext(c);
            setIsContextSet(true);
        });
    }, [mondaySdk]);

    useEffect(() => {
        updateItems();
    }, [updateItems]);

    if (context === undefined || isContextSet === undefined) {
        return <LoadingIndicator />;
    }

    if (!isContextSet) {
        return <GuestViewerUnauthorized />;
    }

    let systemTheme = ThemeProvider.systemThemes.LIGHT;
    switch (context.theme) {
      case "dark": {
        systemTheme = ThemeProvider.systemThemes.DARK;
        break;
      }
      case "black": {
        systemTheme = ThemeProvider.systemThemes.BLACK;
        break;
      }
      default: {
        systemTheme = ThemeProvider.systemThemes.LIGHT;
      }
    }

    let defaultThemeConfig: ThemeConfig = {
        name: "crm-product-theme",
        colors: {
            name: "crm-product-theme",
            black: {
                "brand-colors": {
                    "brand-color": "#007f9b",
                    "brand-hover-color": "#006278",
                    "text-color-on-brand": "#ffffff",
                },
                "primary-color": "#007f9b",
                "primary-highlighted-color": "#07202c",
                "primary-hover-color": "#006278",
                "primary-selected-color": "#0e435d",
                "primary-selected-hover-color": "#2C4e5f",
                "primary-surface-color": "#111111"
            },
            dark: {
                "brand-colors": {
                    "brand-color": "#007f9b",
                    "brand-hover-color": "#006278",
                    "text-color-on-brand": "#ffffff"
                },
                "primary-color": "#007f9b",
                "primary-highlighted-color": "#00293d",
                "primary-hover-color": "#006278",
                "primary-selected-color": "#0e435d",
                "primary-selected-hover-color": "#294e65",
                "primary-surface-color": "#181b34"
            },
            light: {
                "brand-colors": {
                    "brand-color": "#007f9b",
                    "brand-hover-color": "#006278",
                    "text-color-on-brand": "#ffffff"
                },
                "primary-color": "#007f9b",
                "primary-highlighted-color": "#e7f4f6",
                "primary-hover-color": "#006278",
                "primary-selected-color": "#d1ecef",
                "primary-selected-hover-color": "#c6dfe3",
                "primary-surface-color": "#e7f4f6"
            }
        }
    }

    return (
        <ThemeProvider systemTheme={systemTheme} themeConfig={{ name: context.theme, colors: context.themeConfig?.colors ?? defaultThemeConfig.colors }}>
            <MondayContextContext.Provider value={{ context, items }}>
                {children}
            </MondayContextContext.Provider>
        </ThemeProvider>
    );
}