import { DateTime } from 'luxon';

export function getDateTimeForTimezone(timezone: string): Date {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    };
  
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const dateTimeParts = formatter.formatToParts(new Date());
  
    const year = parseInt(dateTimeParts.find(part => part.type === 'year')?.value || '0');
    const month = parseInt(dateTimeParts.find(part => part.type === 'month')?.value || '0') - 1;
    const day = parseInt(dateTimeParts.find(part => part.type === 'day')?.value || '0');
    const hour = parseInt(dateTimeParts.find(part => part.type === 'hour')?.value || '0');
    const minute = parseInt(dateTimeParts.find(part => part.type === 'minute')?.value || '0');
    const second = parseInt(dateTimeParts.find(part => part.type === 'second')?.value || '0');
  
    return new Date(year, month, day, hour, minute, second);
}

export function formatDateTime(dateTime: Date): string {
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function parseDateTime(dateTimeString: string, timezone: string): DateTime {
  const dateTime = DateTime.fromISO(dateTimeString, { zone: timezone });
  return dateTime;
}