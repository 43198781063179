import { useContext, createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import axios from 'axios';
import { useMondaySdk } from '../monday-sdk/monday-sdk-context';
import { HasAccessResponse, SubscriptionStatus, SubscriptionStatusType, checkMondayAccess, getSubscriptionStatus  } from '../repository/auth-repository';
import { LoadingIndicator } from '../common/ui/loading-indicator';
import { TrackingEvent } from '../common/tracking/tracking-event-models';
import { tracking } from '../di/tracking-di';
import { useUserInfo } from '../user/user-info-context';

interface AuthContextType {
    isAxiosInterceptorReady: boolean;
    hasMondayAccess: boolean;
    mondayStateToken: string | null;
    refreshMondayAccess: () => Promise<void>;
    subscriptionStatus: SubscriptionStatus;
    refreshSubscriptionStatus: (currentSubscriptionStatus: SubscriptionStatus) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [sessionToken, setSessionToken] = useState<string | null>(null);
    const [hasMondayAccess, setMondayAccess] = useState<boolean | null>(null);
    const [mondayStateToken, setMondayStateToken] = useState<string | null>(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus>(
        {
            status: SubscriptionStatusType.NOT_INITIALIZED,
            message: "Not initialized"
        }
    );
    
    const { mondaySdk } = useMondaySdk();
    const { userInfo } = useUserInfo();

    // Used to attach an axios interceptor so that all requests have the session token
    const [isAxiosInterceptorReady, setAxiosInterceptorReady] = useState<boolean>(false);

    const getSessionToken = useCallback(async () => {
        try {
            const sessionTokenResponse = await mondaySdk.get("sessionToken");
            setSessionToken(sessionTokenResponse.data);
        } catch (error) {
            await tracking.sendEvent( 
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.SESSION_TOKEN_ERROR,
                {
                    error: JSON.stringify(error)
                }
            );
        }
    }, [mondaySdk, userInfo]);

    // Get the session token to attach to interceptor in axios
    useEffect(() => {
        getSessionToken();
    }, [getSessionToken]);

    // When session token received from monday, attach it to axios interceptor
    useEffect(() => {
        if (!sessionToken) {
            return
        }

        let axiosInterceptor = axios.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${sessionToken}`;
            return config;
        }, error => {
            return Promise.reject(error);
        });
        setAxiosInterceptorReady(true);

        return () => {  
            setAxiosInterceptorReady(false);
            if (axiosInterceptor) {
                axios.interceptors.request.eject(axiosInterceptor);
            }
        };
    }, [sessionToken]);

    const verifyMondayAccess = useCallback(async () => {
        if (!isAxiosInterceptorReady) { 
            setMondayAccess(false);
            setMondayStateToken(null);
            return 
        }
        
        let response: HasAccessResponse = await checkMondayAccess();
        if (response.hasAccess) {
            setMondayAccess(response.hasAccess);
            setMondayStateToken(null);
        } else if (response.stateToken === null) {
            await tracking.sendEvent( 
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.STATE_TOKEN_NOT_RECEIVED,
                {
                    response: JSON.stringify(response)
                }
            );
            setMondayAccess(false);
            setMondayStateToken(null);
        } else {
            await tracking.sendEvent( 
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.MONDAY_ACCESS_FAILED,
                {
                    response: JSON.stringify(response)
                }
            );
            setMondayAccess(false);
            setMondayStateToken(response.stateToken);
        }
    }, [isAxiosInterceptorReady, userInfo]);

    const refreshSubscriptionStatus = useCallback(async (currentSubscriptionStatus: SubscriptionStatus) => {
        if (!isAxiosInterceptorReady || hasMondayAccess === null || !hasMondayAccess) { 
            return
        }
        
        if (currentSubscriptionStatus.status !== SubscriptionStatusType.NOT_INITIALIZED 
            && currentSubscriptionStatus.status !== SubscriptionStatusType.UNKNOWN
        ) {
            return;
        }

        const response: SubscriptionStatus = await getSubscriptionStatus();

        await tracking.sendEvent(
            userInfo.id,
            userInfo.account.id,
            TrackingEvent.REFRESH_SUBSCRIPTION_STATUS,
            { 
                currentStatus: JSON.stringify(currentSubscriptionStatus),
                newStatus: JSON.stringify(response)
            }
        );

        setSubscriptionStatus(response);
    }, [isAxiosInterceptorReady, hasMondayAccess, userInfo]);

    useEffect(() => {
        verifyMondayAccess();
    }, [verifyMondayAccess]);

    useEffect(() => {
        refreshSubscriptionStatus(subscriptionStatus);
    });

    const refreshMondayAccess = useCallback(async () => {
        await verifyMondayAccess();
    }, [verifyMondayAccess]);

    if (!isAxiosInterceptorReady || hasMondayAccess === null) {
        return <LoadingIndicator />
    }

    return (
        <AuthContext.Provider value={{ isAxiosInterceptorReady, hasMondayAccess, mondayStateToken, refreshMondayAccess, subscriptionStatus, refreshSubscriptionStatus }}>
            {children}
        </AuthContext.Provider>
    );
};