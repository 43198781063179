import { Loader } from "monday-ui-react-core";

export const LoadingIndicator = () => {
  return (
    <div className="loading-overlay">
        <div className="loading-indicator-container">
            <Loader color={Loader.colors.PRIMARY} size={Loader.sizes.MEDIUM}/>
        </div>
    </div>
  );
};