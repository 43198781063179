import { useAuth } from "../auth/auth-context";
import { Button, Text } from 'monday-ui-react-core';
import { SubscriptionStatusType } from "../repository/auth-repository";
import { useEffect } from "react";
import { tracking } from "../di/tracking-di";
import { useUserInfo } from "../user/user-info-context";
import { TrackingEvent } from "../common/tracking/tracking-event-models";

function SubscriptionScreen() {
    const { subscriptionStatus, refreshSubscriptionStatus } = useAuth();
    const { userInfo } = useUserInfo();

    useEffect(() => {
        const trackEvent = async () => {
            await tracking.sendEvent(
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.SUBSCRIPTION_PAGE_OPENED,
                {}
            );
        }

        trackEvent();
    }, [userInfo]);

    return (
        <div className="authorization-container">
            <div className="authorization-button-container">
                {
                (subscriptionStatus.status === SubscriptionStatusType.UNKNOWN || 
                    subscriptionStatus.status === SubscriptionStatusType.NOT_INITIALIZED) && 
                <Button
                    onClick={() => refreshSubscriptionStatus(subscriptionStatus)}
                        style={{ marginLeft: '10px' }}
                        disabled={false}
                    >
                    Refresh
                </Button>}
                {subscriptionStatus.status !== SubscriptionStatusType.UNKNOWN && 
                subscriptionStatus.status !== SubscriptionStatusType.NOT_INITIALIZED && 
                <Text>
                    <p style={{ whiteSpace: "normal" }}>{subscriptionStatus.message}</p>
                </Text>}
            </div>
        </div>
    );
}

export default SubscriptionScreen;